import { Box, Switch, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { PaginationOptions, PhoenixBaseTable, PhoenixLink, createMRTPagination } from 'componix'
import dayjs from 'dayjs'
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table'
import React, { useMemo } from 'react'
import { useBoolean } from '../../hooks/useBoolean'
import { CarrierGroupCarrierResponse } from '../../models/Carrier/CarrierGroupCarrierResponse'

const columns: MRT_ColumnDef<CarrierGroupCarrierResponse>[] = [
  {
    header: 'Carrier ID',
    accessorKey: 'carrierId',
    Cell: (table) => (
      <PhoenixLink to={`/carriers/${table.row.original.carrierGuid}`} value={table.row.original.carrierId} />
    ),
    maxSize: 80,
  },
  {
    header: 'NCCI ID',
    accessorKey: 'ncciCode',
    maxSize: 80,
  },
  {
    header: 'NAIC ID',
    accessorKey: 'naicCode',
    maxSize: 80,
  },
  {
    header: 'Carrier Name',
    accessorKey: 'carrierName',
  },
  {
    header: 'Effective Date',
    accessorKey: 'carrierGroupAssocEffectiveDate',
    maxSize: 80,
  },
]

interface CarrierGroupCarriersTableProps {
  data: CarrierGroupCarrierResponse[]
  isLoading: boolean
}

const CarrierGroupCarriersTable = ({ data, isLoading }: CarrierGroupCarriersTableProps) => {
  const { value: showExpiredCarriers, onToggle: toggleShowExpiredCarriers } = useBoolean(false)

  const filteredData = useMemo(
    () =>
      data.filter((carrier) => {
        const isCarrierExpired = dayjs(carrier.carrierGroupAssocExpirationDate).isBefore(dayjs())
        return !isCarrierExpired
      }),
    [data.length]
  )

  const customPagination = useMemo(
    () =>
      createMRTPagination(20, data.length, [
        PaginationOptions.FIVE,
        PaginationOptions.TEN,
        PaginationOptions.TWENTY,
        PaginationOptions.ALL,
      ]),
    [data.length]
  )

  return (
    <PhoenixBaseTable
      columns={columns}
      data={showExpiredCarriers ? data : filteredData}
      isLoading={isLoading}
      overrides={{
        enableFilters: true,
        enableTopToolbar: true,
        renderTopToolbar: ({ table }) => {
          return (
            <Box sx={{ padding: 1, display: 'flex', justifyContent: 'space-between' }}>
              <Box display={'flex'} flexGrow={1}>
                <MRT_GlobalFilterTextField table={table} />
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <FormControlLabel
                  sx={{ marginLeft: '4px' }}
                  control={<Switch checked={showExpiredCarriers} onChange={toggleShowExpiredCarriers} />}
                  label={<Typography variant={'body2'}>Show Expired Carriers</Typography>}
                />
              </Box>
              <Box>{/*Add Buttons Here*/}</Box>
            </Box>
          )
        },
        muiTableBodyRowProps: ({ row }) => {
          const isCarrierExpired = dayjs(row.original.carrierGroupAssocExpirationDate).isBefore(dayjs())

          return {
            sx: {
              '& > .MuiTableCell-root': {
                color: isCarrierExpired ? '#A5A4A5' : 'normal',
              },
            },
          }
        },
        ...customPagination,
      }}
    />
  )
}

export default CarrierGroupCarriersTable
