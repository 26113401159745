import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import React from 'react'
import { ClaimDetailsLossConditionsModel } from '../../models/ClaimDetails/ClaimDetailsLossConditionsModel'

interface ClaimDetailsLossConditionsProps {
  data: ClaimDetailsLossConditionsModel | undefined
  isLoading?: boolean
}

export const ClaimDetailsLossConditionsCard: React.FC<ClaimDetailsLossConditionsProps> = ({
  data,
  isLoading,
}: ClaimDetailsLossConditionsProps) => {
  return (
    <PhoenixBaseCard variantType="Secondary" cardTitle="Loss Conditions" contentPadded defaultWidth={6}>
      <Grid container spacing={1} padding={'8px'} rowGap={'16px'}>
        <Grid item sm={4}>
          <FieldGroup label={'LOSS ACT'} value={data?.lossAct} isLoading={isLoading} />
        </Grid>
        <Grid item sm={4}>
          <FieldGroup label={'LOSS TYPE'} value={data?.lossType} isLoading={isLoading} />
        </Grid>
        <Grid item sm={4}>
          <FieldGroup label={'RECOVERY TYPE'} value={data?.recoveryType} isLoading={isLoading} />
        </Grid>
        <Grid item sm={4}>
          <FieldGroup label={'CLAIM TYPE'} value={data?.claimType} isLoading={isLoading} />
        </Grid>
        <Grid item sm={4}>
          <FieldGroup label={'SETTLEMENT TYPE'} value={data?.settlementType} isLoading={isLoading} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
