import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Step, StepLabel, Stepper } from '@mui/material'
import { PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { MovePolicyResponseModel } from '../../models/MovePolicyResponse'
import { Policy } from '../../models/Policy'
import FindMatchForms from '../../models/WorkItemDetails/FindMatchForms'
import { SuggestedMatchModel } from '../../models/WorkItemDetails/SuggestedMatchModel'
import PhoenixModalHeader from './PhoenixModalHeader'

interface MovePolicyModalProps {
  isOpen: boolean
  onCancel: () => void
  match: SuggestedMatchModel | null
  setFoundMatch: (match: SuggestedMatchModel | null) => void
  successMove: MovePolicyResponseModel[] | null
  handleConfirmMove: () => void
  isMatchLoading: boolean
  isAtConfirm: boolean
  policies: Policy[] | null
}

const MovePolicyModal = ({
  isOpen,
  onCancel,
  handleConfirmMove,
  policies,
  match,
  isMatchLoading,
  successMove,
  isAtConfirm,
  setFoundMatch,
}: MovePolicyModalProps) => {
  const navigate = useNavigate()
  const onCloseAndClear = () => {
    onCancel()
  }
  const closeClearAndNavigate = () => {
    onCloseAndClear()
    navigate(`/coverages/${match?.coverage?.identifier}`)
  }
  const doesPolicyHaveMatch = !!match

  const policiesToMoveColumns = useMemo<MRT_ColumnDef<Policy>[]>(
    () => [
      {
        accessorKey: 'carrierId',
        header: 'CARRIER ID',
        size: 40,
      },

      {
        accessorKey: 'policyNumber',
        header: 'POLICY NO',
        size: 15,
      },
      {
        accessorKey: 'policyEffectiveDate',
        header: 'EFFECTIVE DATE',
        size: 15,
      },
    ],
    []
  )

  /* eslint react/prop-types: 0 */
  const movePolicyResponseColumns = useMemo<MRT_ColumnDef<MovePolicyResponseModel>[]>(
    () => [
      {
        accessorKey: 'comboId',
        header: 'COMBO ID',

        Cell: ({ cell, row }) => (
          <PhoenixLink
            id={`ComboLink-${row.index}`}
            to={`/combos/${row.original.comboGuid}`}
            value={cell.getValue<string>()}
          />
        ),
      },

      {
        accessorKey: 'coverageId',
        header: 'Coverage ID',
        size: 15,
        Cell: ({ cell, row }) => (
          <PhoenixLink
            id={`CoverageLink-${row.index}`}
            to={`/coverages/${row.original.coverageId}`}
            value={cell.getValue<string>()}
            onClick={onCloseAndClear}
          />
        ),
      },
      {
        accessorKey: 'policyNumber',
        header: 'POLICY NO',
        size: 15,
        Cell: ({ cell, row }) => (
          <PhoenixLink
            id={`PolicyNumberLink-${row.index}`}
            to={`/policies/${row.original.policyGuid}`}
            value={cell.getValue<string>()}
          />
        ),
      },
      {
        accessorKey: 'policyEffectiveDate',
        header: 'EFFECTIVE DATE',
        size: 15,
      },
    ],
    []
  )

  return (
    <Dialog fullWidth open={isOpen} maxWidth={'md'} data-testid={'reject-modal'}>
      <PhoenixModalHeader title={'Move Policies'} handleClose={onCloseAndClear} isErrorColor={false} />
      <DialogContent>
        <Box display={'flex'} justifyContent={'center'} mb={2}>
          <Stepper sx={{ minWidth: '240px' }} activeStep={isAtConfirm ? 1 : 0}>
            <Step>
              <StepLabel>Move</StepLabel>
            </Step>
            <Step>
              <StepLabel>Results</StepLabel>
            </Step>
          </Stepper>
        </Box>
        {!isAtConfirm ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <PhoenixBaseTable columns={policiesToMoveColumns} data={policies ?? []} />
            </Grid>
            <Grid item xs={6}>
              <FindMatchForms
                setFoundMatch={setFoundMatch}
                match={match}
                doesPolicyHaveMatch={doesPolicyHaveMatch}
                isModalOpen={isOpen}
              />
            </Grid>
          </Grid>
        ) : (
          <PhoenixBaseTable columns={movePolicyResponseColumns} data={successMove ?? []} />
        )}
      </DialogContent>
      <DialogActions>
        {!isAtConfirm ? (
          <>
            <Button onClick={onCloseAndClear} variant="text">
              Cancel
            </Button>
            <LoadingButton
              data-testid="confirm-move-btn"
              disabled={!doesPolicyHaveMatch || isMatchLoading}
              loading={isMatchLoading}
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleConfirmMove}
            >
              Confirm Move
            </LoadingButton>
          </>
        ) : (
          <Button onClick={closeClearAndNavigate} variant="contained" color="primary">
            Navigate to coverage
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default MovePolicyModal
