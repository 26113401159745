import { Info as InfoIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Stack, TextField, Typography } from '@mui/material'
import { DatePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useQueryClient } from '@tanstack/react-query'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import dayjs, { Dayjs, isDayjs } from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { CacheKeys } from '../../../cache/cacheKeys'
import { useGetControllingCoverageOptions, useGetRatingsImpacted, useMoveCoverage } from '../../../cache/coverageCache'
import { useFindMatchingCombo } from '../../../cache/workQueueCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import { handleTitleCase } from '../../../utils/handleTitleCase'
import { AlertSeverity } from '../../AlertNotification/AlertNotification'
import PhoenixModalHeader from '../PhoenixModalHeader'
import RatingsImpactedSection from './RatingsImpactedSection'
import SetControllingCoverageSection from './SetControllingCoverageSection'

export interface IMoveToForm {
  comboId?: string
  newIncludeDate: Dayjs | string
  originControllingCoverage: string
  destinationControllingCoverage: string
}

export interface SourceCoverageData {
  comboId: string
  comboGuid: string
  coverageId: string
  primaryName: string
  includeDate: string
}

interface MoveCoverageModalProps {
  isOpen: boolean
  onClose: () => void
  sourceCoverage?: SourceCoverageData
}

export const MoveCoverageModal = ({ isOpen, onClose, sourceCoverage }: MoveCoverageModalProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [activeStep, setActiveStep] = useState(0)

  const formMethods = useForm<IMoveToForm>({
    defaultValues: {
      comboId: '',
      newIncludeDate: '',
      originControllingCoverage: '',
      destinationControllingCoverage: '',
    },
    mode: 'all',
  })

  const { control, handleSubmit, register, reset, formState, watch, setError, setValue } = formMethods

  const srcIncludeDateDayjs = dayjs(sourceCoverage?.includeDate)
  const formNewIncludeDate: Dayjs | string = watch('newIncludeDate')
  const formComboId = watch('comboId')
  const originControllingCoverage = watch('originControllingCoverage')
  const destinationControllingCoverage = watch('destinationControllingCoverage')

  useEffect(() => {
    setActiveStep(0)
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])
  //======================================================
  const isNextButtonDisabled = useMemo(() => {
    if (activeStep === 0) {
      return !formState.isDirty || !formState.isValid
    } else if (activeStep === 1) {
      return !originControllingCoverage || !destinationControllingCoverage
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, formNewIncludeDate, formState])
  //======================================================
  const {
    data: matchingCombo,
    refetch: fetchMatchingCombo,
    isLoading: isComboLoading,
    isRefetching: isFetchingMatchingCombo,
  } = useFindMatchingCombo(formComboId!)

  const {
    data: controllingCoverageOptions,
    refetch: fetchControllingCoverageOptions,
    isError: isControllingCoverageOptionsError,
    isLoading: isControllingCoverageOptionsLoading,
    isRefetching: isFetchingControllingCoverageOptions,
  } = useGetControllingCoverageOptions(
    sourceCoverage?.coverageId as string,
    sourceCoverage?.comboGuid as string,
    matchingCombo?.comboGuid as string
  )

  const {
    data: ratingsImpacted,
    refetch: fetchRatingsImpacted,
    isError: isRatingsImpactedError,
    isLoading: isRatingsImpactedLoading,
    isRefetching: isRatingsImpactedFetching,
  } = useGetRatingsImpacted(sourceCoverage?.coverageId as string, {
    transitionDate: isDayjs(formNewIncludeDate) ? formNewIncludeDate.format('MM/DD/YYYY') : '',
    originCombo: sourceCoverage?.comboGuid as string,
    destinationCombo: matchingCombo?.comboGuid as string,
    originControllingCoverage: Number(originControllingCoverage),
    destinationControllingCoverage: Number(destinationControllingCoverage),
  })

  const {
    mutateAsync: moveCoverageFunction,
    isPending: isMoveCoverageLoading,
    isError: isMoveCoverageError,
    isSuccess: isMoveCoverageSuccess,
  } = useMoveCoverage(sourceCoverage?.coverageId as string, {
    originComboGuid: sourceCoverage?.comboGuid as string,
    destinationComboGuid: matchingCombo?.comboGuid,
    originControllingCoverageId: Number(originControllingCoverage),
    destinationControllingCoverageId: Number(destinationControllingCoverage),
    transitionDate: isDayjs(formNewIncludeDate) ? formNewIncludeDate.format('YYYY-MM-DD') : '',
    affectedRatings: [
      ...ratingsImpacted.origin.map((rating) => rating.ratingGuid),
      ...ratingsImpacted.destination.map((rating) => rating.ratingGuid),
    ],
  })

  const fetchAndSetControllingCoverageOptions = async () => {
    const { data } = await fetchControllingCoverageOptions()
    const defaultOrigin = data?.origin?.find((option) => option.selectionReasoning)?.coverageId
    const defaultDestination = data?.destination?.find((option) => option.selectionReasoning)?.coverageId
    const defaultOriginString = defaultOrigin ? defaultOrigin.toString() : ''
    const defaultDestinationString = defaultDestination ? defaultDestination.toString() : ''

    setValue('originControllingCoverage', defaultOriginString)
    setValue('destinationControllingCoverage', defaultDestinationString)

    setActiveStep(1)
  }

  const onNewIncludeDateSubmission = async () => {
    if (formComboId) {
      const result = await fetchMatchingCombo()
      if (!result.data) {
        setError('comboId', {
          message: 'No records to display.',
        })
        return
      }
    } else {
      // Fetch controlling coverage options without destination combo id
      fetchAndSetControllingCoverageOptions()
    }
  }

  useEffect(() => {
    if (matchingCombo?.comboId && formNewIncludeDate.toString() && !isFetchingMatchingCombo) {
      // Fetch controlling coverage options with destination combo id that was found
      // This has to be in a useEffect because of race conditions between api calls
      fetchAndSetControllingCoverageOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingCombo?.comboId, isFetchingMatchingCombo])

  const onSetControllingCoverage = async () => {
    await fetchRatingsImpacted()
    setActiveStep(2)
  }

  const onMoveCoverage = async () => {
    const isNewCombo = !formComboId
    const result = await moveCoverageFunction(isNewCombo)
    navigate(`/combos/${result.result}`)
    // fetch coverage history for both source combo
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.CombinedCoverageHistory, sourceCoverage?.comboGuid as string],
    })
    // fetch coverage history for destination combo
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.CombinedCoverageHistory, result.result],
    })
    closeAndClear()
  }

  //======================================================
  const newIncludeDateErrorMessage = useCallback(
    (error: DateValidationError) => {
      switch (error) {
        case 'invalidDate':
          return 'Invalid Date'
        case 'maxDate':
          return 'New Include Date must not exceed 12 months from current date.'
        case 'shouldDisableDate':
        case 'minDate':
          return 'New Include Date must be greater than the Current Include Date.'
        default:
          return
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState]
  )
  //======================================================

  const onSubmit: SubmitHandler<IMoveToForm> = async () => {
    switch (activeStep) {
      case 0:
        await onNewIncludeDateSubmission()
        break
      case 1:
        await onSetControllingCoverage()
        break
      case 2:
        await onMoveCoverage()
        break
      default:
        return
    }
  }
  //======================================================
  const closeAndClear = () => {
    onClose()
    setActiveStep(0)
    reset()
  }

  useSetAlertDetails([isControllingCoverageOptionsError, isRatingsImpactedError])

  // Move coverage error and success alerts
  useSetAlertDetails([isMoveCoverageError], 'Changes cannot be completed. Please try again.')
  useSetAlertDetails([isMoveCoverageSuccess], 'Coverage moved', AlertSeverity.SUCCESS)

  const isApiCallBeingMade = [
    isComboLoading,
    isFetchingMatchingCombo,
    isControllingCoverageOptionsLoading,
    isFetchingControllingCoverageOptions,
    isRatingsImpactedLoading,
    isRatingsImpactedFetching,
    isMoveCoverageLoading,
  ].some((loading) => loading)

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'md'}
      data-testid={'move-coverage-modal'}
      scroll={'paper'}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <FormProvider {...formMethods}>
        <PhoenixModalHeader title={'Move Coverage'} isErrorColor={false} handleClose={closeAndClear} />
        <DialogContent>
          <Stack direction={'row'} spacing={1} sx={{ m: 1 }}>
            <FieldGroup label={'Current Combo ID'} value={sourceCoverage?.comboId} />
            <FieldGroup label={'Coverage ID'} value={sourceCoverage?.coverageId} />
            <FieldGroup label={'Primary Name'} value={handleTitleCase(sourceCoverage?.primaryName)} />
            <FieldGroup label={'Include Date'} value={sourceCoverage?.includeDate} />
          </Stack>
          <PhoenixBaseCard cardTitle="Move To" variantType="Secondary">
            <Box width={'100%'}>
              {activeStep === 0 && (
                <>
                  <Stack flexDirection={'row'} alignItems={'center'} p={2}>
                    <InfoIcon color={'secondary'} />
                    <Typography variant={'caption'} lineHeight={1.5}>
                      The system will automatically assign a Combo ID if one is not entered.
                    </Typography>
                  </Stack>
                  <Grid container>
                    <Grid item xs={6} p={1}>
                      <Controller
                        name="comboId"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            fullWidth
                            size="small"
                            {...register('comboId', {
                              maxLength: {
                                message: 'Combo ID must not contain more than 9 characters.',
                                value: 9,
                              },
                              pattern: {
                                message: 'Non numeric characters are not allowed.',
                                value: /^[0-9]*$/,
                              },
                              validate: {
                                isSameCombo: (value) => {
                                  return (
                                    value !== sourceCoverage?.comboId || 'Unable to move Coverage(s) to the same Combo'
                                  )
                                },
                              },
                            })}
                            onChange={(value) => {
                              field.onChange(value)
                            }}
                            label="Combo ID"
                            data-testid="combo-id"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || 'Optional'}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} p={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          name="newIncludeDate"
                          control={control}
                          rules={{
                            required: true,
                            validate: (date: Dayjs | string) => {
                              if (isDayjs(date)) {
                                if (!date.isValid()) {
                                  return 'Invalid Date'
                                }
                                if (date.diff(srcIncludeDateDayjs, 'day') < 0) {
                                  return newIncludeDateErrorMessage('minDate')
                                }
                                if (date.diff(srcIncludeDateDayjs, 'day') === 0) {
                                  return newIncludeDateErrorMessage('shouldDisableDate')
                                }
                                if (date.diff(dayjs(), 'day') > 365) {
                                  return newIncludeDateErrorMessage('maxDate')
                                }
                              }
                              return true
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <DatePicker
                              label="New Include Date"
                              //Documentation states that this may have a performance impact
                              //Remove if it causes issues
                              shouldDisableDate={(date) => {
                                return date.diff(srcIncludeDateDayjs, 'day') === 0
                              }}
                              onChange={(date) => {
                                field.onChange(date)
                              }}
                              onError={(error) => {
                                if (error) {
                                  setError('newIncludeDate', { message: newIncludeDateErrorMessage(error) })
                                }
                              }}
                              minDate={srcIncludeDateDayjs}
                              maxDate={dayjs().add(1, 'year')}
                              slotProps={{
                                textField: {
                                  value: field.value,
                                  fullWidth: true,
                                  error: fieldState.invalid,
                                  size: 'small',
                                  helperText: fieldState.error?.message ?? 'Required',
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* Pending  Review Screen*/}
              {activeStep >= 1 && (
                <Grid container p={1}>
                  <Grid item xs={3}>
                    <FieldGroup label={'Combo ID'} value={formComboId ? formComboId : 'NEW'} />
                  </Grid>
                  <Grid item xs={3}>
                    <FieldGroup
                      label={'New Include Date'}
                      value={isDayjs(formNewIncludeDate) ? formNewIncludeDate?.format('MM/DD/YYYY') : ''}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </PhoenixBaseCard>
          <SetControllingCoverageSection
            activeStep={activeStep}
            originComboId={sourceCoverage?.comboId}
            originOptions={controllingCoverageOptions?.origin}
            destinationOptions={controllingCoverageOptions?.destination}
          />
          {activeStep >= 2 && (
            <RatingsImpactedSection
              originComboGuid={sourceCoverage?.comboId}
              destinationComboGuid={matchingCombo?.comboId}
              ratingsImpacted={ratingsImpacted}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ m: 2, position: 'sticky' }}>
          <Button data-testid="cancel-move-btn" size={'small'} variant="text" onClick={closeAndClear}>
            Cancel
          </Button>
          {activeStep > 0 && (
            <Button
              data-testid="submit-move-btn"
              size={'small'}
              variant="contained"
              color="primary"
              disabled={formState.isSubmitting}
              onClick={() => setActiveStep((current) => current - 1)}
            >
              Prev
            </Button>
          )}
          <LoadingButton
            data-testid="submit-move-btn"
            size={'small'}
            variant="contained"
            color="primary"
            type="submit"
            loading={isApiCallBeingMade}
            disabled={isNextButtonDisabled}
          >
            {activeStep === 2 ? 'Confirm Changes' : 'Next'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
