import { downloadFileFromBlob } from '../services/downloadFilesService'
import { CacheKeys } from './cacheKeys'
import useLazyQuery from './useLazyQuery'

const useDownloadBlob = (blobName: string, fileName: string) =>
  useLazyQuery({
    queryKey: [CacheKeys.Attachments, blobName, fileName],
    queryFn: () => downloadFileFromBlob(blobName, fileName),
  })

export { useDownloadBlob }
