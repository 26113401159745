import TabList from '@mui/lab/TabList'
import { Box, Button, DialogActions, Stack, Tab, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { PhoenixBaseTable, PhoenixLink } from 'componix'
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_Row,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useWorkItemsManualClose, useWorkItemsReassignUser } from '../../cache/workQueueCache'
import { useAlertContext } from '../../contexts/AlertContext'
import { useBoolean } from '../../hooks/useBoolean'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { WorkQueueItemModel } from '../../models/WorkQueueItemModel'
import { isWorkItemTypePT1 } from '../../utils/isWorkItemTypePT1'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import ManualCloseModal from '../Modals/ManualCloseModal'
import WorkItemReassignModal from '../Modals/WorkItemReassignModal'

export const WorkQueueColumns = (isOpen: boolean): MRT_ColumnDef<WorkQueueItemModel>[] => {
  /* eslint react/prop-types: 0 */
  const lastColumn = isOpen
    ? {
        accessorKey: 'dueDate',
        header: 'Due Date',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Cell: ({ row }) => {
          return (
            <Typography {...(row.original.isPastDue && { color: 'error' })} fontSize={14}>
              <>{row.original.dueDate}</>
            </Typography>
          )
        },
      }
    : {
        accessorKey: 'closedDate',
        header: 'Close Date',
      }

  return [
    {
      accessorKey: 'workItemNumber',
      header: 'Work Item Number',
      Cell: ({ row }) => {
        const { workItemType, workItemID, workItemNumber } = row.original
        let workItemPath
        switch (workItemType) {
          case 'Unmatched Transaction':
            workItemPath = `unmatched-policies`
            break
          case 'Carrier Error Notification - USR':
            workItemPath = `carrier-errors/unit-stat`
            break
          case 'Carrier Error Notification - Policy':
            workItemPath = 'carrier-errors/policy'
            break
          default:
            break
        }
        return <PhoenixLink to={`${workItemPath}/${workItemID}`} value={workItemNumber} />
      },
    },
    {
      accessorKey: 'workItemType',
      header: 'Work Type',
    },
    {
      accessorKey: 'assignedBy',
      header: 'Assigned By',
    },
    {
      accessorKey: 'assignedDate',
      header: 'Assigned Date',
    },
    lastColumn,
  ]
}

interface WorkQueueTableProps {
  data: WorkQueueItemModel[]
  isLoading: boolean
  handleTabChange: (_: SyntheticEvent, newValue: string) => void
  isOpenTable: boolean
}

const WorkQueueTable = ({ data, isLoading, handleTabChange, isOpenTable }: WorkQueueTableProps) => {
  const queryClient = useQueryClient()
  const { setDetails: setAlertDetails } = useAlertContext()
  const [selectedWorkItems, setSelectedWorkItems] = useState<string[]>([])

  const {
    value: isManualCloseModalOpen,
    onTrue: openManualCloseModal,
    onFalse: closeManualCloseModal,
  } = useBoolean(false)
  const { value: isReassignModalOpen, onTrue: openReassignModal, onFalse: closeReassignModal } = useBoolean(false)

  const {
    mutate: handleManualClose,
    isError: isManualCloseError,
    isSuccess: isManualCloseSuccess,
  } = useWorkItemsManualClose(selectedWorkItems, queryClient)

  const {
    mutate: handleReassign,
    isError: isReassignUserError,
    error: reassignUserError,
    isSuccess: isReassignUserSuccess,
  } = useWorkItemsReassignUser(selectedWorkItems, queryClient)

  useEffect(() => {
    if (isManualCloseSuccess) {
      setAlertDetails({ message: 'Work Item(s) closed successfully.', severity: AlertSeverity.SUCCESS })
    } else if (isReassignUserSuccess) {
      setAlertDetails({ message: 'Work Item(s) reassigned successfully.', severity: AlertSeverity.SUCCESS })
    }
    //eslint-disable-next-line
  }, [isManualCloseSuccess, isReassignUserSuccess])

  useSetAlertDetails([isManualCloseError], 'Work Item(s) cannot be closed. Please try again.')
  useEffect(() => {
    let message = 'Work Item(s) cannot be reassigned. Please try again.'
    if (isReassignUserError) {
      if (reassignUserError.stack?.includes('405')) {
        message = 'Work Item(s) already assigned to user. Please select a different user.'
      }
      setAlertDetails({
        message: message,
        severity: AlertSeverity.ERROR,
      })
    }
  }, [isReassignUserError])

  return (
    <Stack>
      <PhoenixBaseTable
        columns={WorkQueueColumns(isOpenTable)}
        data={data}
        isLoading={isLoading}
        showTableWithoutData
        enableRowSelection
        overrides={{
          ...(isOpenTable && { enableRowSelection: true }),
          enableFilters: true,
          enableTopToolbar: true,
          positionToolbarAlertBanner: 'none',
          renderTopToolbar: ({ table }) => {
            const handleReassign = () => {
              const rows: string[] = []
              table.getSelectedRowModel().flatRows.forEach((row: MRT_Row<WorkQueueItemModel>) => {
                rows.push(row.original.workItemID)
              })

              setSelectedWorkItems(rows)
              openReassignModal()
            }

            const handleClose = () => {
              const rows: string[] = []
              table.getSelectedRowModel().flatRows.forEach((row: MRT_Row<WorkQueueItemModel>) => {
                rows.push(row.original.workItemID)
              })
              setSelectedWorkItems(rows)
              openManualCloseModal()
            }

            const noRowsSelected = !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            const pt1Selected = table
              .getSelectedRowModel()
              .flatRows.some((row: MRT_Row<WorkQueueItemModel>) => isWorkItemTypePT1(row.original.workItemType))
            return (
              <>
                <Box
                  display="flex"
                  justifyContent={'space-between'}
                  flexGrow={1}
                  width={'100%'}
                  className={'toolbar-custom-actions'}
                >
                  <>
                    {/* TabList should be a sibling of TabPanels, but down here to override styling and match designs */}
                    <TabList onChange={handleTabChange}>
                      <Tab label={'Open'} value={'0'}></Tab>
                      <Tab label={'Closed'} value={'1'}></Tab>
                    </TabList>
                  </>
                  {isOpenTable && (
                    <>
                      <DialogActions>
                        <Button size="small" variant="contained" disabled={noRowsSelected} onClick={handleReassign}>
                          Reassign
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          disabled={noRowsSelected || pt1Selected}
                          onClick={handleClose}
                        >
                          Manually Close
                        </Button>
                      </DialogActions>
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backgroundColor: (theme) => theme.palette.grey.A200,
                    paddingTop: 1,
                    paddingRight: 1,
                  }}
                >
                  <MRT_GlobalFilterTextField table={table} />
                  <MRT_ToggleGlobalFilterButton table={table} />
                  <MRT_ToggleFiltersButton table={table} />
                </Box>
              </>
            )
          },
        }}
      />

      <ManualCloseModal
        isOpen={isManualCloseModalOpen}
        onCancel={closeManualCloseModal}
        onManualClose={(responseText: string) => handleManualClose(responseText)}
      />

      <WorkItemReassignModal
        isOpen={isReassignModalOpen}
        onCancel={closeReassignModal}
        onReassign={(userEmail: string) => handleReassign(userEmail)}
      />
    </Stack>
  )
}

export default WorkQueueTable
