import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, StatusBadge } from 'componix'
import React from 'react'
import PolicyDetailSummaryModel from '../../models/PolicyDetails/PolicyDetailSummaryModel'

interface PolicyDetailsSummaryCardProps {
  data: PolicyDetailSummaryModel | undefined
  isLoading?: boolean
}

export const PolicyDetailsSummaryCard = ({ isLoading, data }: PolicyDetailsSummaryCardProps) => {
  const itemBreakPointVars = {
    xs: 12,
    sm: 4,
  }

  return (
    <PhoenixBaseCard variantType="Secondary" cardTitle="Policy Summary" contentPadded defaultWidth={6}>
      <Grid container spacing={1} padding={'8px'}>
        <Grid item sm={itemBreakPointVars.sm}>
          <FieldGroup label={'Carrier ID'} value={data?.carrierId} isLoading={isLoading} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm}>
          <FieldGroup label={'Carrier Name'} value={data?.carrierName} multiLineWrap isLoading={isLoading} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm}>
          <FieldGroup label={'Policy Number'} value={data?.policyNumber} isLoading={isLoading} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm}>
          <FieldGroup label={'Effective Date'} value={data?.effectiveDate} isLoading={isLoading} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm}>
          <FieldGroup label={'Expiration Date'} value={data?.expirationDate} isLoading={isLoading} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm}>
          <FieldGroup label={'Issue Date'} value={data?.issueDate} isLoading={isLoading} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm}>
          <FieldGroup
            label={'Status'}
            value={
              <Grid item>
                <StatusBadge description={data?.status as string} />
              </Grid>
            }
            isLoading={isLoading}
          />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm}>
          <FieldGroup label={'Status Date'} value={data?.statusDate} isLoading={isLoading} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm}>
          <FieldGroup label={'Received Date'} value={data?.receivedDate} isLoading={isLoading} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
export default PolicyDetailsSummaryCard
