import { MRT_ColumnDef } from 'material-react-table'
import { EmployerLocations } from '../../models/PolicyDetails/PolicyDetailsEmployerLocations'
import { tableCellTitleCase } from '../../utils/table/tableCellTitleCase'
import { tableCellZipCode } from '../../utils/table/tableCellZipCode'

/* eslint react/prop-types: 0 */
export const PolicyEmployerLocationsColumns: MRT_ColumnDef<EmployerLocations>[] = [
  {
    accessorKey: 'address.street',
    header: 'Address',
    Cell: ({ cell }) => tableCellTitleCase(cell.getValue<string>()),
  },
  {
    accessorKey: 'address.city',
    header: 'City',
    Cell: ({ cell }) => tableCellTitleCase(cell.getValue<string>()),
  },
  {
    accessorKey: 'address.state',
    header: 'State',
  },
  {
    accessorKey: 'address.zipCode',
    header: 'Zip Code',
    Cell: ({ cell }) => tableCellZipCode(cell.getValue<string>()),
  },
  {
    accessorKey: 'effectiveDate',
    header: 'Effective Date',
  },
  {
    accessorKey: 'expirationDate',
    header: 'Expiration Date',
  },
  {
    accessorKey: 'receivedDate',
    header: 'Received Date',
  },
]

export default PolicyEmployerLocationsColumns
