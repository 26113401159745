import { PhoenixBaseCard } from 'componix'
import { useState } from 'react'
import { useGetSuggestedMatches } from '../../cache/workQueueCache'
import { useMatchPolicyToCoverage } from '../../hooks/workQueue/useMatchPolicyToCoverage'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { UnmatchedTransactionModel } from '../../models/WorkItemDetails/UnmatchedTransactionModel'
import SearchByEmployerNameModal from '../Modals/SearchByEmployerNameModal'
import MatchPolicyToCoverageModal from './MatchPolicyToCoverageModal'
import SuggestedMatchesTable from './SuggestedMatchesTable'

interface SuggestedMatchesProps {
  unmatchedTransaction: UnmatchedTransactionModel | undefined
  uuid: string
  workItemStatus: string | undefined
}

const SuggestedMatches = ({ uuid, unmatchedTransaction, workItemStatus }: SuggestedMatchesProps) => {
  const [openSearchByEmployerNameModal, setOpenSearchByEmployerNameModal] = useState(false)
  const {
    isModalOpen,
    openModal,
    closeModal,
    match,
    successMatch,
    activeStep,
    handleConfirmMatch,
    isMatchLoading,
    setFoundMatch,
  } = useMatchPolicyToCoverage()

  const isWorkItemStatusOpen = workItemStatus === 'Open'

  const {
    data: suggestedMatches,
    isPending: isSuggestedMatchesLoading,
    isError: isSuggestMatchesError,
  } = useGetSuggestedMatches(uuid)

  useSetAlertDetails([isSuggestMatchesError])

  return (
    <>
      <PhoenixBaseCard
        cardTitle="Suggested Matches"
        kebabable={isWorkItemStatusOpen}
        kebabMenuItems={[
          {
            label: 'Search by Employer Name',
            to: '',
            onClick: () => setOpenSearchByEmployerNameModal(true),
          },
        ]}
      >
        <SuggestedMatchesTable
          matches={suggestedMatches ?? []}
          onMatchClick={openModal}
          isWorkItemStatusOpen={isWorkItemStatusOpen}
          isLoading={isSuggestedMatchesLoading}
        />
      </PhoenixBaseCard>
      <MatchPolicyToCoverageModal
        data={unmatchedTransaction}
        isOpen={isModalOpen}
        handleClose={closeModal}
        setFoundMatch={setFoundMatch}
        match={match}
        successMatch={successMatch}
        isMatchLoading={isMatchLoading}
        activeStep={activeStep}
        handleConfirmMatch={handleConfirmMatch}
      />
      <SearchByEmployerNameModal
        isOpen={openSearchByEmployerNameModal && !isModalOpen}
        onCancel={() => setOpenSearchByEmployerNameModal(false)}
        onMatchClick={openModal}
      />
    </>
  )
}

export default SuggestedMatches
