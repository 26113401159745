import { PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import { LossRecord } from '../../../models/UnitStatLossRecordsModels'
import { tableCellMoney } from '../../../utils/table/tableCellMoney'

/* eslint react/prop-types: 0 */
export const UnitStatLossRecordsTableColumns: MRT_ColumnDef<LossRecord>[] = [
  {
    accessorKey: 'updateType',
    header: 'Update Type',
  },
  {
    accessorKey: 'claimNumber',
    header: 'Claim Number',
    Cell: ({ cell, row }) => (
      <PhoenixLink to={`/claims/${row.original.unitStatLossId}`} value={cell.getValue<string>()} />
    ),
  },
  {
    accessorKey: 'accidentDate',
    header: 'Accident Date',
  },
  {
    accessorKey: 'class',
    header: 'Class',
  },
  {
    accessorKey: 'incurredIndemnity',
    header: 'Incurred Indemnity',
    Cell: ({ cell }) => tableCellMoney(cell.getValue<number>()),
  },
  {
    accessorKey: 'incurredMedical',
    header: 'Incurred Medical',
    Cell: ({ cell }) => tableCellMoney(cell.getValue<number>()),
  },
  {
    accessorKey: 'paidIndemnity',
    header: 'Paid Indemnity',
    Cell: ({ cell }) => tableCellMoney(cell.getValue<number>()),
  },
  {
    accessorKey: 'paidMedical',
    header: 'Paid Medical',
    Cell: ({ cell }) => tableCellMoney(cell.getValue<number>()),
  },
]
