import { QueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { PolicyErrorResponseModel } from '../models/PolicyDetails/PolicyErrorResponseModel'
import { UpdateErrorsModel } from '../models/PolicyDetails/UpdateErrorsModel'
import { UnitStatDeductibleModel } from '../models/UnitStatDeductibleModel'
import UnitStatDetailsModel from '../models/UnitStatDetailsModel'
import UnitStatExposuresResponse from '../models/UnitStatExposuresModel'
import { UnitStatLossRecordsModel } from '../models/UnitStatLossRecordsModels'
import UnitStatPolicyConditionsModel from '../models/UnitStatPolicyConditionsModel'
import UnitStatPolicyInformationModel from '../models/UnitStatPolicyInfoModel'
import { UnitStatPolicyTypeModel } from '../models/UnitStatPolicyTypeModel'
import {
  getUnitStatDeductible,
  getUnitStatDetails,
  getUnitStatErrors,
  getUnitStatExposures,
  getUnitStatLossRecords,
  getUnitStatPolicyConditions,
  getUnitStatPolicyInfo,
  getUnitStatPolicyType,
  updateUnitStatErrors,
} from '../services/unitStatService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'
import useLazyQuery from './useLazyQuery'

const useGetUnitStatDetails = (unitStatId: string) =>
  useQuery<UnitStatDetailsModel>({
    queryKey: [CacheKeys.UnitStatDetails, unitStatId],
    queryFn: () => getUnitStatDetails(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!unitStatId,
  })

const useGetUnitStatPolicyInfo = (unitStatId: string) =>
  useQuery<UnitStatPolicyInformationModel>({
    queryKey: [CacheKeys.UnitStatPolicyInfo, unitStatId],
    queryFn: () => getUnitStatPolicyInfo(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!unitStatId,
  })

const useGetUnitStatPolicyConditions = (unitStatId: string) =>
  useLazyQuery<UnitStatPolicyConditionsModel>({
    queryKey: [CacheKeys.UnitStatPolicyConditions, unitStatId],
    queryFn: () => getUnitStatPolicyConditions(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
  })

const useGetUnitStatPolicyType = (unitStatId: string) =>
  useLazyQuery<UnitStatPolicyTypeModel>({
    queryKey: [CacheKeys.UnitStatPolicyType, unitStatId],
    queryFn: () => getUnitStatPolicyType(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
  })

const useGetUnitStatDeductible = (unitStatId: string) =>
  useLazyQuery<UnitStatDeductibleModel>({
    queryKey: [CacheKeys.UnitStatDeductible, unitStatId],
    queryFn: () => getUnitStatDeductible(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
  })

const useGetUnitStatExposures = (unitStatId: string) =>
  useLazyQuery<UnitStatExposuresResponse>({
    queryKey: [CacheKeys.UnitStatExposures, unitStatId],
    queryFn: () => getUnitStatExposures(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
  })

const useGetUnitStatLossRecords = (unitStatId: string) =>
  useLazyQuery<UnitStatLossRecordsModel>({
    queryKey: [CacheKeys.UnitStatLossRecords, unitStatId],
    queryFn: () => getUnitStatLossRecords(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
  })

const useGetUnitStatErrors = (unitStatId: string) =>
  useLazyQuery<PolicyErrorResponseModel>({
    queryKey: [CacheKeys.UnitStatErrors, unitStatId],
    queryFn: () => getUnitStatErrors(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
  })

// ====== MUTATION ====== //

const useUpdateUnitStatErrors = (unitStatId: string, queryClient: QueryClient) =>
  useMutation({
    mutationFn: (request: UpdateErrorsModel) => updateUnitStatErrors(unitStatId, request),
    onSuccess: () => {
      queryClient.fetchQuery({
        queryKey: [CacheKeys.UnitStatErrors, unitStatId],
        queryFn: () => getUnitStatErrors(unitStatId),
      })
      queryClient.invalidateQueries({ queryKey: [CacheKeys.UnitStatDetails, unitStatId] })
    },
  })

export {
  useGetUnitStatErrors,
  useGetUnitStatLossRecords,
  useGetUnitStatExposures,
  useGetUnitStatDeductible,
  useGetUnitStatPolicyType,
  useGetUnitStatPolicyConditions,
  useGetUnitStatPolicyInfo,
  useGetUnitStatDetails,
  useUpdateUnitStatErrors,
}
