import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { FieldGroup, NoResultsFound, PhoenixBaseCard, PhoenixBaseTable } from 'componix'
import { useState } from 'react'
import { useGetPolicyEmployersLocations } from '../../cache/policyCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { handleTitleCase } from '../../utils/handleTitleCase'
import PolicyEmployerLocationsColumns from './PolicyEmployerLocationsColumns'

interface PolicyEmployersLocationsProps {
  uuid: string
}

export const PolicyEmployersLocations = ({ uuid }: PolicyEmployersLocationsProps) => {
  const [showNamesOnly, setShowNamesOnly] = useState(false)
  const { data, isLoading, isError, lazyFetch } = useGetPolicyEmployersLocations(uuid)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard
      cardTitle={'Employers/Locations'}
      isLoading={isLoading}
      collapsible
      onExpand={lazyFetch}
      contentPadded
    >
      <Grid
        container
        sx={{
          maxHeight: showNamesOnly ? '450px' : '1010px',
          overflowY: 'auto',
        }}
      >
        {data && data.length > 0 ? (
          <>
            <FormGroup sx={{ paddingLeft: '8px' }}>
              <FormControlLabel
                sx={{
                  fontSize: '10px',
                }}
                control={
                  <Switch
                    id="showNamesOnly-switch"
                    checked={showNamesOnly}
                    onChange={() => setShowNamesOnly(!showNamesOnly)}
                  />
                }
                label={
                  <Typography variant="body1" fontSize={'13px'}>
                    Show Names Only
                  </Typography>
                }
              />
            </FormGroup>

            <Grid item xs={12}>
              {data.map((employer, index) => (
                <PhoenixBaseCard
                  cardTitle={employer.name !== null ? handleTitleCase(employer.name) : '----'}
                  isLoading={isLoading}
                  variantType="Secondary"
                  key={index}
                >
                  <Grid container padding={1}>
                    <Grid item xs={3}>
                      <FieldGroup label={'Email Address'} value={employer.emailAddress?.toLowerCase()} />
                    </Grid>
                    <Grid item xs={3}>
                      <FieldGroup label={'Name Link'} value={employer.nameLink} />
                    </Grid>
                    <Grid item xs={3}>
                      <FieldGroup label={'Cont Seq. Number'} value={employer.continuationSequenceNumber} />
                    </Grid>
                    <Grid item xs={3}>
                      <FieldGroup label={'FEIN'} value={employer.fein} />
                    </Grid>
                    <Grid item xs={3}>
                      <FieldGroup label={'Effective Date'} value={employer.effectiveDate} />
                    </Grid>
                    <Grid item xs={3}>
                      <FieldGroup label={'Expiration Date'} value={employer.expirationDate} />
                    </Grid>
                    <Grid item xs={3}>
                      <FieldGroup label={'Received Date'} value={employer.receivedDate} />
                    </Grid>
                    <Grid item xs={3}>
                      <FieldGroup label={'PEO/Client'} value={employer.peoCode} />
                    </Grid>
                  </Grid>
                  {!showNamesOnly && (
                    <Grid item sm={12}>
                      <PhoenixBaseTable columns={PolicyEmployerLocationsColumns} data={employer.locations} />
                    </Grid>
                  )}
                </PhoenixBaseCard>
              ))}
            </Grid>
          </>
        ) : (
          <NoResultsFound />
        )}
      </Grid>
    </PhoenixBaseCard>
  )
}

export default PolicyEmployersLocations
