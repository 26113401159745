import { QueryKey, UndefinedInitialDataOptions, UseQueryResult, useQuery } from '@tanstack/react-query'
import { useCallback, useState } from 'react'

type UseLazyQueryResult<TData> = Omit<UseQueryResult<TData, unknown>, 'refetch'> & {
  lazyFetch: () => void
}

export default function useLazyQuery<TData>(
  options: UndefinedInitialDataOptions<TData, Error, TData, QueryKey>
): UseLazyQueryResult<TData> {
  const [enabled, setEnabled] = useState(false)

  const query = useQuery<TData, Error, TData, QueryKey>({ ...options, enabled })

  const lazyFetch = useCallback(() => {
    if (!enabled) {
      setEnabled(true)
    }
  }, [enabled])

  return { lazyFetch, ...query }
}
