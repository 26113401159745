import { Box } from '@mui/material'
import { BreadcrumbBar } from 'componix'
import { UUID } from 'crypto'
import { useParams } from 'react-router-dom'
import { useGetAncestors } from '../cache/ancestorCache'
import { useGetCarrierGroupGutterInfo } from '../cache/carrierCache'
import CarrierGroupGutterCard from '../components/Carrier/CarrierGroupLeftGutterCard'
import CarrierDetailsCard from '../components/Carriers/CarrierDetailsCard'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'
import { HierarchyTypes } from '../utils/hierarchy.enum'

const CarrierDetails = () => {
  const { pageStyle } = useNavbar()
  const { carrierGuid } = useParams()
  const { data: ancestorData } = useGetAncestors(carrierGuid as UUID, HierarchyTypes.CARRIER)
  const {
    data: carrierGroupData,
    isPending: isCarrierGroupLoading,
    isError: isCarrierGroupError,
  } = useGetCarrierGroupGutterInfo(carrierGuid as string)

  useSetAlertDetails([isCarrierGroupError])

  return (
    <Box style={{ ...pageStyle }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Carriers`,
            to: `/carriers`,
          },
          {
            label: `Carrier Group ${ancestorData?.carrierGroup?.identifier}`,
            to: `/carriers/group/${ancestorData?.carrierGroup?.guid}`,
          },
          {
            label: `Carrier ${ancestorData?.carrier?.identifier}`,
            to: `/carriers/${carrierGuid}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={<CarrierDetailsCard guid={carrierGuid} />}
        sideRailComponentArray={[
          <CarrierGroupGutterCard carrierGroup={carrierGroupData} isLoading={isCarrierGroupLoading} />,
        ]}
      />
    </Box>
  )
}

export default CarrierDetails
