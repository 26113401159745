import { PhoenixBaseCard, PhoenixBaseTable } from 'componix'
import { CoverageGroupProfileEmployers, CoverageGroupProfileLocations } from '../../models/CoverageGroupProfileResponse'
import { MRT_ColumnDef } from 'material-react-table'
import { useGetCoverageGroupProfile } from '../../cache/coverageCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { tableCellTitleCase } from '../../utils/table/tableCellTitleCase'


const EmployersColumns: MRT_ColumnDef<CoverageGroupProfileEmployers>[] = [
    {
        accessorKey: 'insuredName',
        header: 'Insured Name',
        Cell: ({cell}) => tableCellTitleCase(cell.getValue<string>())
    },
    {
        accessorKey: 'effectiveDate',
        header: 'Effective Date'
    },
    {
        accessorKey: 'expirationDate',
        header: 'Expiration Date'
    }
]
const LocationsColumns: MRT_ColumnDef<CoverageGroupProfileLocations>[] = [
    {
        accessorKey: 'street',
        header: 'Street',
        Cell: ({cell}) => tableCellTitleCase(cell.getValue<string>()),

    },
    {
        accessorKey: 'city',
        header: 'City',
        Cell: ({cell}) => tableCellTitleCase(cell.getValue<string>()),
        maxSize:180
    },
    {
        accessorKey: 'state',
        header: 'State',
        maxSize: 120
    },
    {
        accessorKey: 'zipCode',
        header: 'Zip Code',
        maxSize: 120
    },
    {
        accessorKey: 'firstPolicyEffectiveDate',
        header: 'First Policy Effective Date',
        maxSize: 120

    },
    {
        accessorKey:'lastPolicyExpirationDate',
        header: 'Last Policy Expiration Date',
        maxSize: 120
    }
]

interface CoverageGroupProfileProps{
    coverageId: string
}
export const CoverageGroupProfile = ({coverageId}: CoverageGroupProfileProps)=>{
    const {
        lazyFetch,
        data,
        isLoading,
        isError,
    } =useGetCoverageGroupProfile(coverageId)
    useSetAlertDetails([isError])
    return(
        <PhoenixBaseCard cardTitle="Coverage Group Profile" collapsible contentPadded onExpand={lazyFetch} isLoading={isLoading}>
            <PhoenixBaseCard cardTitle="Employers" variantType="Secondary">
                <PhoenixBaseTable
                columns={EmployersColumns}
                data={data?.employers ?? []} />
            </PhoenixBaseCard>
            <PhoenixBaseCard cardTitle="Locations" variantType="Secondary">
                <PhoenixBaseTable
                    columns={LocationsColumns}
                    data={data?.locations ?? []}
                />
            </PhoenixBaseCard>
        </PhoenixBaseCard>
    )
}
