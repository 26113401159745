import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, PhoenixLink, StatusBadge } from 'componix'
import { UUID } from 'crypto'
import UnitStatDetailsModel from '../../../models/UnitStatDetailsModel'

interface UnitStatInfoGutterCardProps {
  unitStat: UnitStatDetailsModel | undefined
  uuid: UUID | null | undefined
  isLoading: boolean
}

const UnitStatInfoGutterCard = ({ unitStat, uuid, isLoading }: UnitStatInfoGutterCardProps) => {
  if (!unitStat && !isLoading) return <></>

  return (
    <PhoenixBaseCard cardTitle="Unit Stat Information">
      <Grid container margin={2}>
        <Grid item xs={12} pb={2}>
          <StatusBadge description={unitStat?.status} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FieldGroup
            label="Report Level"
            value={<PhoenixLink to={`/unitstats/${uuid}`} value={unitStat?.reportLevel} />}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FieldGroup label="Correction Seq" value={unitStat?.correctionSequence} isLoading={isLoading} />
        </Grid>
        <FieldGroup label="Correction Type" value={unitStat?.correctionType} isLoading={isLoading} />
        <FieldGroup
          label="Accepted Date"
          value={unitStat?.acceptedDate}
          icon={<CalendarTodayIcon />}
          isLoading={isLoading}
        />
        <FieldGroup
          label="Received Date"
          value={unitStat?.receivedDate}
          icon={<CalendarTodayIcon />}
          isLoading={isLoading}
        />
      </Grid>
    </PhoenixBaseCard>
  )
}

export default UnitStatInfoGutterCard
