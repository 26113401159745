/* eslint-disable react/prop-types */
import { Box, Button } from '@mui/material'
import { PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { AddressDTOModel } from '../../models/AddressDTOModel'
import { SuggestedMatchModel } from '../../models/WorkItemDetails/SuggestedMatchModel'
import { addressFormatter } from '../../utils/addressFormatter'
import { tableCellTitleCase } from '../../utils/table/tableCellTitleCase'

const SuggestedMatchesColumns: MRT_ColumnDef<SuggestedMatchModel>[] = [
  {
    accessorKey: 'combo.identifier',
    header: 'Combo ID',
    maxSize: 80,
    Cell: ({ cell, row }) => (
      <PhoenixLink
        id={`Combo-${row.index}`}
        to={`/combos/${row.original.combo?.guid}`}
        value={cell.getValue<string>()}
      />
    ),
  },
  {
    accessorKey: 'coverage.identifier',
    header: 'Coverage ID',
    maxSize: 80,
    Cell: ({ cell, row }) => (
      <PhoenixLink
        id={`Coverage-${row.index}`}
        to={`/coverages/${row.original.coverage?.identifier}`}
        value={cell.getValue<string>()}
      />
    ),
  },
  {
    accessorKey: 'employer',
    header: 'Employer',
    Cell: ({ cell }) => {
      return tableCellTitleCase(cell.getValue<string>())
    },
  },
  {
    accessorKey: 'address',
    header: 'Address',
    minSize: 200,
    Cell: ({ cell }) => {
      return addressFormatter(cell.getValue<AddressDTOModel>())
    },
  },
  {
    accessorKey: 'fein',
    header: 'FEIN',
    maxSize: 80,
  },
]

interface SuggestedMatchesTableProps {
  matches: SuggestedMatchModel[]
  onMatchClick?: (match: SuggestedMatchModel) => void
  isWorkItemStatusOpen: boolean
  isLoading?: boolean
}

const SuggestedMatchesTable = ({
  matches,
  onMatchClick,
  isWorkItemStatusOpen,
  isLoading,
}: SuggestedMatchesTableProps) => {
  return (
    <PhoenixBaseTable
      columns={SuggestedMatchesColumns}
      data={matches}
      isLoading={isLoading}
      overrides={{
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => {
          return isWorkItemStatusOpen ? (
            <Box display={'flex'} justifyContent={'flex-end'} pr={'4px'}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => onMatchClick && onMatchClick(row.original)}
              >
                Match
              </Button>
            </Box>
          ) : (
            <></>
          )
        },
      }}
    />
  )
}

export default SuggestedMatchesTable
