import { Grid } from '@mui/material'
import { FieldGroup } from 'componix'
import { UnitStatCarrierErrorResponseModel } from '../../../models/WorkItemDetails/CarrierErrorNotification'

interface USRCarrierErrorHeaderProps {
  error: UnitStatCarrierErrorResponseModel | undefined
  isLoading: boolean
}

export const USRCarrierErrorHeader = ({ error, isLoading }: USRCarrierErrorHeaderProps) => {
  return (
    <Grid container padding={1}>
      <Grid item xs={3}>
        <FieldGroup label="Notice Date" value={error?.noticeDate} isLoading={isLoading} />
      </Grid>
      <Grid item xs={3}>
        <FieldGroup label="Fine Due Date" value={error?.fineDueDate} isLoading={isLoading} />
      </Grid>
      <Grid item xs={3}>
        <FieldGroup label="Fine Status" value={error?.fineStatus} isLoading={isLoading} />
      </Grid>
      <Grid item xs={3}>
        <FieldGroup label="Fine Status Date" value={error?.fineStatusDate} isLoading={isLoading} />
      </Grid>
    </Grid>
  )
}
