import { CalendarToday, CreditCard, Person, Receipt } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { FieldGroup, NoResultsFound, PhoenixBaseCard } from 'componix'
import { useGetPolicyExposure } from '../../cache/policyCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { formatMoney } from '../../utils/moneyFormatter'
import ExposureTable from './ExposureTable/ExposureTable'

interface ExposureProps {
  uuid: string
}

export const Exposure = ({ uuid }: ExposureProps) => {
  const { data, isLoading, isError, lazyFetch } = useGetPolicyExposure(uuid)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard collapsible={true} onExpand={lazyFetch} cardTitle="Exposure" isLoading={isLoading}>
      {data?.summary?.periodEffectiveDate === '' ? (
        <NoResultsFound />
      ) : (
        <>
          <Grid
            paddingLeft={'8px'}
            paddingTop={'8px'}
            flexDirection={'row'}
            container
            spacing={1}
            marginBottom={'16px'}
          >
            <Grid item md={2.3}>
              <FieldGroup
                label={'PERIOD EFFECTIVE DATE'}
                value={data?.summary?.periodEffectiveDate}
                icon={<CalendarToday sx={{ marginTop: '6px' }} />}
              />
            </Grid>
            <Grid item md={2}>
              <FieldGroup
                label={'ESTIMATED PAYROLL'}
                value={formatMoney(data?.summary?.estimatedPayroll)}
                icon={<Receipt sx={{ marginTop: '6px' }} />}
              />
            </Grid>
            <Grid item md={2.2}>
              <FieldGroup
                label={'CALCULATED PREMIUM'}
                value={formatMoney(data?.summary?.calculatedPremium)}
                icon={<CreditCard sx={{ marginTop: '6px' }} />}
              />
            </Grid>
            <Grid item md={2}>
              <FieldGroup
                label={'GOVERNING CLASS'}
                value={data?.summary?.governingClass}
                icon={<Person sx={{ marginTop: '6px' }} />}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <ExposureTable isLoading={isLoading} searchData={data?.table ?? []} />
            </Grid>
          </Grid>
        </>
      )}
    </PhoenixBaseCard>
  )
}
export default Exposure
