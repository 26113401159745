import { Box, Button } from '@mui/material'
import { DataFieldBlankValue, KebabNavigationMenu, PhoenixBaseTable, PhoenixLink, StatusBadge } from 'componix'
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table'
import { useEffect, useState } from 'react'
import { Policy } from '../../models/Policy'

/* eslint react/prop-types: 0 */
const policyColumns: MRT_ColumnDef<Policy>[] = [
    {
      accessorKey: 'carrierId',
      header: 'CARRIER ID',
      size: 40,
      Cell: ({ cell, row }) => (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box paddingRight={'8px'}>
              <KebabNavigationMenu
                id={`PolicyListNavButton-${row.index}`}
                menuItems={[
                  {
                    label: 'View Policy Details',
                    id: `policyDetailsNavMenuItem-0`,
                    to: `/policies/${row.original.policyId}`,
                  },
                ]}
              />
            </Box>
            <>{cell.getValue<string>()}</>
          </Box>
        </>
      ),
    },
    {
      accessorKey: 'carrierName',
      header: 'CARRIER NAME',
      size: 200,
    },
    {
      accessorKey: 'policyNumber',
      header: 'POLICY NO',
      Cell: ({ cell, row }) => (
        <PhoenixLink
          value={cell.getValue<string>()}
          to={`/policies/${row.original.policyId}`}
        />
      ),
      size: 15,
    },
    {
      accessorKey: 'policyEffectiveDate',
      header: 'EFFECTIVE DATE',
      size: 15,
    },
    {
      accessorKey: 'policyExpirationDate',
      header: 'EXPIRATION DATE',
      size: 15,
    },
    {
      accessorKey: 'status',
      header: 'STATUS',
      Cell: ({ cell }) => (
        <StatusBadge description={cell.getValue<string>()} />
      ),
      size: 15,
    },
    {
      accessorKey: 'statusDate',
      header: 'STATUS DATE',
      size: 15,
    },
    {
      accessorKey: 'issueDate',
      header: 'ISSUE DATE',
      size: 15,
    },
    {
      accessorKey: 'unitStatReport.dueDate',
      header: 'USR DUE DATE',
      size: 15,
      Cell: ({ row }) =>
        row.original.unitStatReport != null && row.original.unitStatReport.nextDue != null
          ? `${row.original.unitStatReport.nextDue} - ${row.original.unitStatReport.dueDate}`
          : <DataFieldBlankValue />,
    },
  ]

export interface PoliciesTableProps {
  policyListData: Policy[] | undefined
  isLoading: boolean
  isRefetching: boolean
  openMovePolicyModal: () => void
  setPoliciesToMove: (policies: Policy[]) => void
}

export const PoliciesTable = ({
  policyListData = [],
  isLoading,
  isRefetching,
  openMovePolicyModal,
  setPoliciesToMove,
}: PoliciesTableProps) => {

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
  useEffect(() => {
    if (isRefetching) setRowSelection({})
  }, [isRefetching])

  return (
    <PhoenixBaseTable
      data-testid="mrtDataTable"
      data={policyListData}
      isLoading={isLoading}
      columns={policyColumns}
      overrides={{
        enableRowSelection: true,
        enableGlobalFilter: true,
        enableFilters: true,
        enableTopToolbar: true,
        positionToolbarAlertBanner: 'none',
        initialState: {
          pagination: { pageSize: 5, pageIndex: 0 },
          density: 'comfortable',
          showGlobalFilter: false,
          showColumnFilters: false,
        },
        state: { rowSelection },
        onRowSelectionChange: setRowSelection,
        renderTopToolbarCustomActions: ({ table }) => (
          <Box>
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ToggleGlobalFilterButton table={table} />
          </Box>
        ),
        renderToolbarInternalActions: ({ table }) => (
          <Box>
            <Button
              variant="contained"
              disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
              onClick={() => {
                if (table.getSelectedRowModel().flatRows.length > 0) {
                  openMovePolicyModal()
                  setPoliciesToMove(table.getSelectedRowModel().flatRows.map((row) => row.original))
                }
              }}
            >
              Move Policy
            </Button>
          </Box>
        ),
      }}
    />
  )
}

export default PoliciesTable
