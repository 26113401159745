import { Box } from '@mui/material'
import { PhoenixBaseCard } from 'componix'
import React from 'react'
import { useGetCarrierList } from '../cache/carrierCache'
import CarriersTable from '../components/Carrier/CarriersTable'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'

const Carriers = () => {
  const { pageStyle } = useNavbar()
  const { data = [], isPending, isError } = useGetCarrierList()

  useSetAlertDetails([isError])

  return (
    <Box style={{ ...pageStyle, marginBottom: '24px' }}>
      <PhoenixBaseCard cardTitle={'Carriers'}>
        <CarriersTable data={data} isLoading={isPending} />
      </PhoenixBaseCard>
    </Box>
  )
}

export default Carriers
