import { Chip, Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import React from 'react'
import PolicyDetailsInformationModel from '../../models/PolicyDetails/PolicyDetailsInformationModel'

interface PolicyDetailsInformationCardProps {
  data: PolicyDetailsInformationModel | undefined
  isLoading?: boolean
}

export const PolicyDetailsInformationCard = ({ isLoading, data }: PolicyDetailsInformationCardProps) => {
  const itemBreakPointVars = {
    xs: 12,
    sm: 4,
    md: 4,
  }

  return (
    <PhoenixBaseCard variantType="Secondary" cardTitle="Policy Information" contentPadded defaultWidth={6}>
      <Grid container spacing={1} padding={'8px'}>
        <Grid item sm={12} height={'64px'} display={'flex'} alignItems={'center'} gap={'16px'}>
        <Chip disabled={!data?.isMultistate} label={'Multi-State'} />
        <Chip disabled={!data?.isWrapUp} label={'Wrap-Up'} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} padding={'8px'}>
          <FieldGroup label={'Policy Type'} value={data?.policyType} isLoading={isLoading} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} padding={'8px'}>
          <FieldGroup
            label={'Employee Leasing'}
            value={data?.employeeLeasingType}
            multiLineWrap
            isLoading={isLoading}
          />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} padding={'8px'}>
          <FieldGroup label={'Policy Term Code'} value={data?.policyTermCode} isLoading={isLoading} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} padding={'8px'}>
          <FieldGroup label={'State Effective Date'} value={data?.stateEffectiveDate} isLoading={isLoading} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
export default PolicyDetailsInformationCard
