import { Box, Chip, Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, StatusBadge } from 'componix'
import { useGetCarrierDetails } from '../../cache/carrierCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'

interface CarrierDetailsCardProps {
  guid: string | undefined
}

const CarrierDetailsCard = ({ guid }: CarrierDetailsCardProps) => {
  const { data, isError, isPending } = useGetCarrierDetails(guid as string)

  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle={'Carrier Details'}>
      <Grid container padding={2} columns={10} spacing={1}>
        <Grid item xs={2}>
          <FieldGroup
            label={'Status'}
            value={
              <Box>
                <StatusBadge description={data?.statusCode} isLoading={isPending} />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={2}>
          <FieldGroup label={'Status Date'} value={data?.statusEffectiveDate} isLoading={isPending} />
        </Grid>
        <Grid item xs={2}>
          <FieldGroup label={'Loss Cost Multiplier'} value={data?.lossCostMultiplier} isLoading={isPending} />
        </Grid>
        <Grid item xs={2}>
          <FieldGroup
            label={'LCM Effective Date'}
            value={data?.lossCostMultiplierEffectiveDate}
            isLoading={isPending}
          />
        </Grid>
        <Grid item xs={2}>
          <FieldGroup label={'Data Team'} value={data?.teamNumber} isLoading={isPending} />
        </Grid>
        <Grid item>
          <Chip label={'Receive WCTRATING'} disabled={!data?.recieveWCRatingFlag} />
        </Grid>
        <Grid item>
          <Chip label={'Skip Unit Stat Tracking'} disabled={!data?.skipUnitStatTrackingFlag} />
        </Grid>
        <Grid item>
          <Chip label={'WCCRIT USR'} disabled={!data?.wccritusrFlag} />
        </Grid>
        <Grid item>
          <Chip label={'WCPOLS Reporting'} disabled={!data?.wcpolReportingFlag} />
        </Grid>
        <Grid item>
          <Chip label={'WCSTAT Reporting'} disabled={!data?.wcstatReportingFlag} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}

export default CarrierDetailsCard
