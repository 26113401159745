import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, booleanToYN } from 'componix'
import React from 'react'
import { ClaimDetailsInformationModel } from '../../models/ClaimDetails/ClaimDetailsInformationModel'
import { formatMoney } from '../../utils/moneyFormatter'
import { StateCodes } from '../../utils/stateCodes'

interface ClaimDetailsInformationCardProps {
  data: ClaimDetailsInformationModel | undefined
  isLoading?: boolean
}

export const ClaimDetailsInformationCard: React.FC<ClaimDetailsInformationCardProps> = ({
  data,
  isLoading,
}: ClaimDetailsInformationCardProps) => {
  return (
    <PhoenixBaseCard variantType="Secondary" cardTitle="Claim Information" contentPadded>
      <Grid container spacing={1} padding={'8px'} rowGap={'16px'}>
        <Grid item sm={2}>
          <FieldGroup label={'Incurred Indemnity'} value={formatMoney(data?.incurredIndemnity)} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup label={'Paid Indemnity'} value={formatMoney(data?.paidIndemnity)} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup label={'Incurred Medical'} value={formatMoney(data?.incurredMedical)} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup label={'Paid Medical'} value={formatMoney(data?.paidMedical)} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup label={'Paid ALAE'} value={formatMoney(data?.paidALAE)} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup label={'Injury Type'} value={data?.injuryType} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'Status'} value={data?.status} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup
            label={'Jurisdiction State'}
            value={StateCodes[String(data?.jurisdictionState)]}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'Catastrophe Type'} value={data?.catastropheType} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'MCO Type'} value={data?.mcoType} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'Lump Sum'} value={booleanToYN(data?.lumpSum)} isLoading={isLoading} />
        </Grid>
        <Grid item sm={4.8}>
          <FieldGroup label={'Occupation Description'} value={data?.occupationDescription} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'Fraud Claim'} value={data?.fraudClaim} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'WCB Case Number'} value={data?.wcbCaseNumber} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup
            label={'Average Weekly Wage'}
            value={formatMoney(data?.averageWeeklyWage)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
