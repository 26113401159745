import { PhoenixBaseCard, PhoenixBaseTable, PhoenixLink, StatusBadge } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useGetComboOwnership } from '../../cache/comboCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { ComboOwnershipResponse } from '../../models/WorkItemDetails/ComboOwnershipResponse'
import { tableCellTitleCase } from '../../utils/table/tableCellTitleCase'

const columns: MRT_ColumnDef<ComboOwnershipResponse>[] = [
  {
    accessorKey: 'entityName',
    header: 'Entity Name',
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => tableCellTitleCase(cell.getValue<string>()),
    minSize: 240,
  },
  {
    accessorKey: 'coverageId',
    header: 'Coverage ID',
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => <PhoenixLink to={`/coverages/${cell.getValue<string>()}`} value={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'effectiveDate',
    header: 'Effective Date',
  },
  {
    accessorKey: 'expirationDate',
    header: 'Expiration Date',
  },
  {
    accessorKey: 'status',
    header: 'Status',
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'manual',
    header: 'Manual',
    maxSize: 80,
  },
  {
    accessorKey: 'notes',
    header: 'Notes',
    maxSize: 80,
  },
  {
    accessorKey: 'naEffectiveDate',
    header: 'Not Allowed Effective Date',
  },
  {
    accessorKey: 'isTrust',
    header: 'Trusts',
    maxSize: 80,
  },
  {
    accessorKey: 'isNonProfit',
    header: 'NonProfits',
    maxSize: 80,
  },
]

interface ComboOwnershipProps {
  comboId: string | undefined
}

const ComboOwnership = ({ comboId }: ComboOwnershipProps) => {
  const { data = [], isPending, isError } = useGetComboOwnership(comboId as string)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle={'Ownership'}>
      <PhoenixBaseTable
        columns={columns}
        data={data}
        isLoading={isPending}
        overrides={{
          enableFilters: true,
          enableTopToolbar: true,
        }}
      />
    </PhoenixBaseCard>
  )
}

export default ComboOwnership
