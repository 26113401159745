import { useQuery } from '@tanstack/react-query'
import {
  getCarrierDetails,
  getCarrierGroupCarrierList,
  getCarrierGroupInfo,
  getCarrierGroupInfoByCarrierID,
  getCarrierList,
} from '../services/carrierService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'

const useGetCarrierList = () =>
  useQuery({
    queryKey: [CacheKeys.Carriers],
    queryFn: getCarrierList,
    staleTime: StaleTime.ONE_MINUTE,
  })

const useGetCarrierGroupInfo = (carrierGuid: string) =>
  useQuery({
    queryKey: [CacheKeys.CarrierGroupInfo, carrierGuid],
    queryFn: () => getCarrierGroupInfo(carrierGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid,
  })

const useGetCarrierGroupCarrierList = (carrierGuid: string) =>
  useQuery({
    queryKey: [CacheKeys.CarrierGroupCarrierList, carrierGuid],
    queryFn: () => getCarrierGroupCarrierList(carrierGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid,
  })

const useGetCarrierGroupGutterInfo = (carrierGuid: string) => {
  return useQuery({
    queryKey: [CacheKeys.CarrierGroupGutterInfo, carrierGuid],
    queryFn: () => getCarrierGroupInfoByCarrierID(carrierGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid,
  })
}
const useGetCarrierDetails = (carrierGuid: string) =>
  useQuery({
    queryKey: [CacheKeys.CarrierDetails, carrierGuid],
    queryFn: () => getCarrierDetails(carrierGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid,
  })

export {
  useGetCarrierList,
  useGetCarrierGroupInfo,
  useGetCarrierGroupCarrierList,
  useGetCarrierGroupGutterInfo,
  useGetCarrierDetails,
}
