import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { AlertNotification } from './components/AlertNotification/AlertNotification'
import TopMenuBar from './components/TopMenuBar/TopMenuBar'
import { AlertContextProvider } from './contexts/AlertContext'
import { NavbarProvider } from './contexts/NavbarContext'
import { SearchProvider } from './contexts/SearchContext'
import CarrierErrorType from './models/CarrierErrorType'
import CarrierDetails from './pages/CarrierDetails'
import { CarrierErrorNotifications } from './pages/CarrierErrorNotifications'
import CarrierGroup from './pages/CarrierGroup'
import Carriers from './pages/Carriers'
import { ClaimDetails } from './pages/ClaimDetails'
import Combo from './pages/Combo'
import { Coverages } from './pages/Coverages'
import PolicyDetail from './pages/PolicyDetail'
import { RatingDetails } from './pages/RatingDetails'
import { Search } from './pages/Search'
import { UnitStatDetails } from './pages/UnitStatDetails'
import { UnmatchedPolicies } from './pages/UnmatchedPolicies'
import { WorkQueue } from './pages/WorkQueue'
import theme from './styles/theme'

const AppRouter: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
      },
    },
  })

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <NavbarProvider>
            <AlertContextProvider>
              <TopMenuBar />
              <AlertNotification />
              <SearchProvider>
                <Routes>
                  <Route path="/coverages" element={<Coverages />} />
                  <Route path="/coverages/:coverageId" element={<Coverages />} />
                  <Route path="/policies/:policyId" element={<PolicyDetail />} />
                  <Route path="/unitstats/:unitStatId" element={<UnitStatDetails />} />
                  <Route path="/combos/:comboId" element={<Combo />} />
                  <Route path="/ratings/:ratingId" element={<RatingDetails />} />
                  <Route path="/claims/:unitStatLossId" element={<ClaimDetails />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/carriers/:carrierGuid" element={<CarrierDetails />} />
                  <Route path="carriers/group/:groupGuid" element={<CarrierGroup />} />
                  <Route path="/carriers" element={<Carriers />} />
                  <Route path="workqueue/unmatched-policies/:workItemId" element={<UnmatchedPolicies />} />
                  <Route
                    path={`workqueue/carrier-errors/${CarrierErrorType.UNIT_STAT}/:workItemId`}
                    element={<CarrierErrorNotifications type={CarrierErrorType.UNIT_STAT} />}
                  />
                  <Route
                    path={`workqueue/carrier-errors/${CarrierErrorType.POLICY}/:workItemId`}
                    element={<CarrierErrorNotifications type={CarrierErrorType.POLICY} />}
                  />
                  <Route path="/workqueue" element={<WorkQueue />} />
                  <Route path="*" element={<Navigate to="/workqueue" />} />
                </Routes>
              </SearchProvider>
            </AlertContextProvider>
          </NavbarProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </Router>
  )
}

export default AppRouter
