import { Download } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Dialog } from '@mui/material'
import { PhoenixBaseCard, PhoenixBaseTable } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import * as React from 'react'
import { useDownloadBlob } from '../../cache/attachmentsCache'

interface AttachmentsModalProps {
  isOpen: boolean
  onCancel: () => void
  attachments: AttachmentsProps[]
}

interface AttachmentsProps {
  name: string
  path: string
}

interface DownloadButtonProps {
  blobName: string
  fileName: string
}

const DownloadButton = ({ blobName, fileName }: DownloadButtonProps) => {
  const { isLoading, lazyFetch } = useDownloadBlob(blobName, fileName)
  return (
    <LoadingButton
      variant="contained"
      color="primary"
      size="small"
      endIcon={<Download />}
      loading={isLoading}
      onClick={() => {
        lazyFetch()
      }}
    >
      Download
    </LoadingButton>
  )
}

export default function AttachmentsModal({ isOpen, onCancel, attachments }: AttachmentsModalProps) {
  const handleClose = () => {
    onCancel()
  }

  const columns: MRT_ColumnDef<AttachmentsProps>[] = [
    {
      accessorKey: 'name',
      header: 'File Name',
      size: 250,
    },
    {
      accessorKey: 'path',
      header: '',
      size: 0,
      grow: false,
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      Cell: ({ cell }: any) => {
        return cell.getValue() && <DownloadButton blobName={cell.getValue()} fileName={cell.row.original.name} />
      },
    },
  ]
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth={'xs'}
      data-testid={'attachments-modal'}
      sx={{
        '& .MuiDialog-container > .MuiPaper-root': {
          borderRadius: '8px',
        },
      }}
    >
      <PhoenixBaseCard cardTitle="Attachments" closable onClose={handleClose}>
        <PhoenixBaseTable
          columns={columns}
          data={attachments}
          overrides={{
            muiTableHeadRowProps: {
              sx: { display: 'none' },
            },
          }}
        />
      </PhoenixBaseCard>
    </Dialog>
  )
}
