import { DataFieldBlankValue } from 'componix'

export const formatMoney = (value: number | undefined) => {
  if (value === undefined) return <DataFieldBlankValue />
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value)
}
