import { MRT_ColumnDef } from 'material-react-table'
import PolicyDetailsPremiumModel from '../../../models/PolicyDetails/PolicyDetailsPremiumModel'
import { tableCellMoney } from '../../../utils/table/tableCellMoney'

/* eslint react/prop-types: 0 */
export const PolicyPremiumsTableColumns: MRT_ColumnDef<PolicyDetailsPremiumModel>[] = [
  {
    accessorKey: 'periodEffective',
    header: 'Period Effective Date',
  },
  {
    accessorKey: 'totalEstimatedStandardPremium',
    header: 'Total Est STND Premium',
    size: 190,
    Cell: ({ cell }) => tableCellMoney(cell.getValue<number>()),
  },
  {
    accessorKey: 'ratingEffective',
    header: 'Rating Effective Date',
  },
  {
    accessorKey: 'modEffective',
    header: 'Mod Effective Date',
  },
  {
    accessorKey: 'modFactor',
    header: 'Mod Factor',
  },
  {
    accessorKey: 'premiumDiscount',
    header: 'Premium Discount',
    Cell: ({ cell }) => tableCellMoney(cell.getValue<number>()),
  },
  {
    accessorKey: 'sifCreditDebit',
    header: 'SIF Credit/Debit',
    Cell: ({ cell }) => tableCellMoney(cell.getValue<number>()),
  },
  {
    accessorKey: 'statePremium',
    header: 'State Premium',
    Cell: ({ cell }) => tableCellMoney(cell.getValue<number>()),
  },
  {
    accessorKey: 'changeEffective',
    header: 'Change Effective Date',
  },
  {
    accessorKey: 'changeExpiration',
    header: 'Change Expiration Date',
  },
]

export default PolicyPremiumsTableColumns
