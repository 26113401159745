import { Box, Button } from '@mui/material'
import { PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useMatchPolicyToCoverage } from '../../hooks/workQueue/useMatchPolicyToCoverage'
import { UnmatchedTransactionModel } from '../../models/WorkItemDetails/UnmatchedTransactionModel'
import { addressFormatter } from '../../utils/addressFormatter'
import { tableCellTitleCase } from '../../utils/table/tableCellTitleCase'
import MatchPolicyToCoverageModal from './MatchPolicyToCoverageModal'

interface UnmatchedTransactionSummaryProps {
  data?: UnmatchedTransactionModel | null
  isLoading: boolean
}

const UnmatchedTransactionSummary = ({ data, isLoading }: UnmatchedTransactionSummaryProps) => {
  const {
    isModalOpen,
    closeModal,
    openModal,
    activeStep,
    handleConfirmMatch,
    isMatchLoading,
    setFoundMatch,
    match,
    successMatch,
  } = useMatchPolicyToCoverage()

  /* eslint react/prop-types: 0 */
  const columns = useMemo<MRT_ColumnDef<UnmatchedTransactionModel>[]>(
    () => [
      {
        accessorKey: 'combo',
        header: 'Combo ID',
        maxSize: 80,
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ row }) => (
          <PhoenixLink
            id={`Combo-${row.index}`}
            to={`/combos/${row.original.combo?.guid}`}
            value={row.original.combo?.identifier?.toString()}
          />
        ),
      },
      {
        accessorKey: 'coverage',
        header: 'Coverage ID',
        maxSize: 80,
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ row }) => (
          <PhoenixLink
            id={`Coverage-${row.index}`}
            to={`/coverages/${row.original.coverage?.identifier}`}
            value={row.original.coverage?.identifier?.toString()}
          />
        ),
      },
      {
        accessorKey: 'employer',
        header: 'Employer',
        Cell: ({ cell }: any) => {
          return tableCellTitleCase(cell.getValue())
        },
      },
      {
        accessorKey: 'address',
        header: 'Address',
        minSize: 200,
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ cell }: any) => {
          return addressFormatter(cell.getValue())
        },
      },
      {
        accessorKey: 'fein',
        header: 'FEIN',
        maxSize: 80,
      },
      {
        accessorKey: 'isWorkItemOpen',
        header: '',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ cell }: any) => {
          return (
            cell.getValue() && (
              <Box sx={{ textAlign: 'right' }}>
                <Button variant="contained" color="primary" size="small" onClick={() => openModal()}>
                  Find Match
                </Button>
              </Box>
            )
          )
        },
      },
    ],
    // eslint-disable-next-line
    []
  )

  return (
    <>
      <PhoenixBaseTable data={data ? [data] : []} columns={columns} isLoading={isLoading} />
      <MatchPolicyToCoverageModal
        data={data}
        isOpen={isModalOpen}
        handleClose={closeModal}
        activeStep={activeStep}
        match={match}
        successMatch={successMatch}
        setFoundMatch={setFoundMatch}
        handleConfirmMatch={handleConfirmMatch}
        isMatchLoading={isMatchLoading}
      />
    </>
  )
}

export default UnmatchedTransactionSummary
