import { Grid } from '@mui/material'
import { FieldGroup } from 'componix'
import { PolicyCarrierErrorResponseModel } from '../../../models/WorkItemDetails/CarrierErrorNotification'

interface PolicyCarrierErrorHeaderProps {
  error: PolicyCarrierErrorResponseModel | undefined
  isLoading: boolean
}

export const PolicyCarrierErrorHeader = ({ error, isLoading }: PolicyCarrierErrorHeaderProps) => {
  return (
    <Grid container p={1}>
      <Grid item xs={2}>
        <FieldGroup label={'Txn Code'} value={error?.txnCode} isLoading={isLoading} />
      </Grid>
      <Grid item xs={2}>
        <FieldGroup label={'Txn Issue Date'} value={error?.txnIssue} isLoading={isLoading} />
      </Grid>
      <Grid item xs={2}>
        <FieldGroup label={'Notice Date'} value={error?.noticeDate} isLoading={isLoading} />
      </Grid>
      <Grid item xs={2}>
        <FieldGroup label={'Fine Due Date'} value={error?.fineDueDate} isLoading={isLoading} />
      </Grid>
      <Grid item xs={2}>
        <FieldGroup label={'Fine Status'} value={error?.fineStatus} isLoading={isLoading} />
      </Grid>
      <Grid item xs={2}>
        <FieldGroup label={'Fine Status Date'} value={error?.fineStatusDate} isLoading={isLoading} />
      </Grid>
    </Grid>
  )
}
