import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import React from 'react'
import { ClaimDetailsInjuryDescCodesModel } from '../../models/ClaimDetails/ClaimDetailsInjuryDescCodesModel'

interface ClaimDetailsInjuryDescriptionCodesCardProps {
  data: ClaimDetailsInjuryDescCodesModel | undefined
  isLoading?: boolean
}

export const ClaimDetailsInjuryDescriptionCodesCard: React.FC<ClaimDetailsInjuryDescriptionCodesCardProps> = ({
  data,
  isLoading,
}: ClaimDetailsInjuryDescriptionCodesCardProps) => {
  return (
    <PhoenixBaseCard variantType="Secondary" cardTitle="Injury Description Codes" contentPadded defaultWidth={6}>
      <Grid container spacing={1} padding={'8px'} rowGap={'16px'}>
        <Grid item sm={4}>
          <FieldGroup label={'Part of Injury'} value={data?.partOfInjury} isLoading={isLoading} />
        </Grid>
        <Grid item sm={4}>
          <FieldGroup label={'Nature of Injury'} value={data?.natureOfInjury} isLoading={isLoading} />
        </Grid>
        <Grid item sm={4}>
          <FieldGroup label={'Cause of Injury'} value={data?.causeOfInjury} isLoading={isLoading} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
