import { PhoenixBaseCard } from 'componix'
import React from 'react'
import { useGetPolicyPremiums } from '../../cache/policyCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import PolicyPremiumsTable from './PolicyPremiumsTable'

interface PolicyPremiumsProps {
  uuid: string
}
export const PolicyPremiumsContent = ({ uuid }: PolicyPremiumsProps) => {
  const { data = [], isLoading, isError, lazyFetch } = useGetPolicyPremiums(uuid)

  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle={'Premiums'} collapsible={true} onExpand={lazyFetch} isLoading={isLoading}>
      <PolicyPremiumsTable data={data} />
    </PhoenixBaseCard>
  )
}
export default PolicyPremiumsContent
