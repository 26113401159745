import CarrierDetailsResponse from '../models/Carrier/CarrierDetailsResponse'
import { CarrierGroupCarrierResponse } from '../models/Carrier/CarrierGroupCarrierResponse'
import CarrierGroupInfoResponse from '../models/Carrier/CarrierGroupInfoResponse'
import CarrierResponse from '../models/Carrier/CarrierResponse'
import api from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

const getCarrierList = async (): Promise<CarrierResponse[]> => {
  const response = await api.get(`${Endpoints.Carriers}`)
  return response.data
}

const getCarrierGroupInfo = async (carrierGuid: string): Promise<CarrierGroupInfoResponse> => {
  const response = await api.get(`${Endpoints.Carriers}/groups/${carrierGuid}/info`)
  return response.data
}

const getCarrierGroupCarrierList = async (carrierGuid: string): Promise<CarrierGroupCarrierResponse[]> => {
  const response = await api.get(`${Endpoints.Carriers}/groups/${carrierGuid}/carriers`)
  return response.data
}
const getCarrierGroupInfoByCarrierID = async (carrierID: string) => {
  const response = await api.get(`${Endpoints.Carriers}/${carrierID}/group-info`)
  return response.data
}

const getCarrierDetails = async (carrierGuid: string): Promise<CarrierDetailsResponse> => {
  const response = await api.get(`${Endpoints.Carriers}/${carrierGuid}/details`)
  return response.data
}

export {
  getCarrierList,
  getCarrierGroupInfo,
  getCarrierGroupCarrierList,
  getCarrierGroupInfoByCarrierID,
  getCarrierDetails,
}
