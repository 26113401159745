import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box, Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, PhoenixLink } from 'componix'
import CarrierGroupInfo from '../../models/Carrier/CarrierGroupInfo'

interface CarrierGroupGutterCardProps {
  carrierGroup: CarrierGroupInfo | undefined
  isLoading: boolean
}

const CarrierGroupGutterCard = ({ carrierGroup, isLoading }: CarrierGroupGutterCardProps) => {
  return (
    <PhoenixBaseCard cardTitle="Group Information" contentPadded={true}>
      <Box sx={{ padding: '16px', width: '100%' }}>
        <FieldGroup
          label="Group ID"
          value={<PhoenixLink to={`/carriers/group/${carrierGroup?.groupGuid}`} value={carrierGroup?.groupID} />}
          isLoading={isLoading}
        />
        <FieldGroup label="Group Name" value={carrierGroup?.groupName} isLoading={isLoading} />
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <FieldGroup label="NCCI Group ID" value={carrierGroup?.ncciGroupID} isLoading={isLoading} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FieldGroup label="NAIC Group ID" value={carrierGroup?.naicGroupID} isLoading={isLoading} />
          </Grid>
        </Grid>
        <FieldGroup
          label="Effective Date"
          value={carrierGroup?.effectiveDate}
          icon={<CalendarTodayIcon />}
          isLoading={isLoading}
        />
        <FieldGroup
          label="Expiration Date"
          value={carrierGroup?.expirationDate}
          icon={<CalendarTodayIcon />}
          isLoading={isLoading}
        />
      </Box>
    </PhoenixBaseCard>
  )
}

export default CarrierGroupGutterCard
