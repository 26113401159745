import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { Box } from '@mui/material'
import { useGetClosedWorkItems, useGetOpenWorkItems } from '../../cache/workQueueCache'
import { useTabs } from '../../hooks/useTabs'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import WorkQueueTable from './WorkQueueTable'

const WorkQueueTabs = () => {
  const { tabIndex, handleTabChange } = useTabs()

  const { isLoading: isOpenItemsLoading, isError: openItemsError, data: openItems = [] } = useGetOpenWorkItems()
  const { isPending: isClosedItemsLoading, isError: closedItemsError, data: closedItems = [] } = useGetClosedWorkItems()

  useSetAlertDetails([openItemsError, closedItemsError])

  return (
    <Box>
      <TabContext value={tabIndex}>
        {/* TabList should generally live here, but inside WorkQueue table to override styling */}
        <TabPanel value="0" sx={{ padding: 0 }}>
          <WorkQueueTable
            data={openItems}
            isLoading={isOpenItemsLoading}
            handleTabChange={handleTabChange}
            isOpenTable
          />
        </TabPanel>
        <TabPanel value="1" sx={{ padding: 0 }}>
          <WorkQueueTable
            data={closedItems}
            isLoading={isClosedItemsLoading}
            handleTabChange={handleTabChange}
            isOpenTable={false}
          />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default WorkQueueTabs
