import { MRT_ColumnDef } from 'material-react-table'
import { ExposureDTO } from '../../../models/SplitsModel'
import { tableCellFloat } from '../../../utils/table/tableCellFloat'
import { tableCellMoney } from '../../../utils/table/tableCellMoney'

/* eslint react/prop-types: 0 */
export const ExposureDtoColumns: MRT_ColumnDef<ExposureDTO>[] = [
  {
    accessorKey: 'updateType',
    header: 'Update Type',
  },
  {
    accessorKey: 'exposureACTCode',
    header: 'Expo ACT',
  },
  {
    accessorKey: 'classCode',
    header: 'Class',
  },
  {
    accessorKey: 'exposureAmount',
    header: 'Exposure Amount',
    Cell: ({ cell }) => tableCellMoney(cell.getValue<number>()),
  },
  {
    accessorKey: 'manualRate',
    header: 'Manual Rate',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 3),
  },
  {
    accessorKey: 'premiumRate',
    header: 'Premium Amount',
    Cell: ({ cell }) => tableCellMoney(cell.getValue<number>()),
  },
]

export default ExposureDtoColumns
