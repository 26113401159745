import { PhoenixBaseCard, PhoenixLink, StatusBadge, TableWithSubrows, TableWithSubrowsProps } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import { RatingHistoryDTO } from '../../models/RatingHistoryDTO'

/* eslint react/prop-types: 0 */
const columns: MRT_ColumnDef<RatingHistoryDTO>[] = [
  { accessorKey: 'effectiveDate', size: 130, header: 'RATING EFFECTIVE DATE' },
  { accessorKey: 'revisionNumber', header: 'REVISION #', size: 20 },
  {
    accessorKey: 'hasInterstateID',
    header: 'INTERSTATE ID INDICATOR',
    maxSize: 105,
  },
  {
    accessorKey: 'modFactor',
    maxSize: 90,
    header: 'MOD FACTOR',
    Cell: ({ row }) => (
      <PhoenixLink to={`/ratings/${row.original.ratingID}`} value={row.original.modFactor?.toString()} />
    ),
  },
  {
    accessorKey: 'transitionalModCap',
    maxSize: 100,
    header: 'TRANSITIONAL MOD CAP',
  },
  { accessorKey: 'result', maxSize: 110, header: 'RESULT' },
  {
    accessorKey: 'status',
    maxSize: 100,
    header: 'STATUS',
    Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>()} />,
  },
  { accessorKey: 'statusDate', maxSize: 90, header: 'STATUS DATE' },
  { accessorKey: 'statusBy', maxSize: 80, header: 'STATUS BY' },
]

export interface RatingHistoryProps {
  data: RatingHistoryDTO[] | undefined
  isLoading: boolean
}

const RatingHistory = ({ data, isLoading }: RatingHistoryProps) => {
  const navMenuOptions: TableWithSubrowsProps<RatingHistoryDTO>['kebabMenuOptions'] = [
    {
      label: 'View Rating Details',
      to: ({ row }) => `/ratings/${row.original.ratingID}`,
    },
  ]

  return (
    <PhoenixBaseCard cardTitle={'Rating History'}>
      <TableWithSubrows
        columns={columns}
        data={data ?? []}
        isLoading={isLoading}
        kebabColumnHeader=""
        kebabMenuOptions={navMenuOptions}
      />
    </PhoenixBaseCard>
  )
}

export default RatingHistory
