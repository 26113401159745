import { CalendarToday, Person } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import React from 'react'
import { ClaimDetailsSummaryModel } from '../../models/ClaimDetails/ClaimDetailsSummaryModel'

interface ClaimDetailsSummaryCardProps {
  data: ClaimDetailsSummaryModel | undefined
  isLoading?: boolean
}

export const ClaimDetailsSummaryCard: React.FC<ClaimDetailsSummaryCardProps> = ({
  data,
  isLoading,
}: ClaimDetailsSummaryCardProps) => {
  return (
    <PhoenixBaseCard variantType="Secondary" cardTitle="Claim Summary" contentPadded>
      <Grid container spacing={1} padding={'8px'} rowGap={'16px'}>
        <Grid item sm={2}>
          <FieldGroup label={'Update Type'} value={data?.updateType} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup label={'Claim Number'} value={data?.claimNumber} isLoading={isLoading} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup
            label={'Accident Date'}
            value={data?.accidentDate}
            icon={<CalendarToday />}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item sm={6}>
          <FieldGroup label={'Class'} value={data?.class} icon={<Person />} isLoading={isLoading} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
