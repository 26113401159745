import { useGetCoverageGroupOwnerShip } from '../../cache/coverageCache'
import { FieldGroup, NoResultsFound, PhoenixBaseCard, PhoenixBaseTable, StatusBadge } from 'componix'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import { CoverageGroupOwner, CoverageGroupOwnershipModel } from '../../models/CoverageGroupOwnershipModel'
import { Divider, Grid, useTheme } from '@mui/material'
import { formatPercent } from '../../utils/percentFormatter'
import { handleTitleCase } from '../../utils/handleTitleCase'
import { tableCellTitleCase } from '../../utils/table/tableCellTitleCase'

const columns: MRT_ColumnDef<CoverageGroupOwnershipModel>[] = [
  {
    accessorKey: 'entityName',
    header: 'Entity Name',
    Cell: ({ cell }) => tableCellTitleCase(cell.getValue<string>()),
    maxSize: 120,
  },
  { accessorKey: 'effectiveDate', header: 'Effective Date', maxSize: 110 },
  { accessorKey: 'expirationDate', header: 'Expiration Date', maxSize: 110 },
  {
    accessorKey: 'status',
    header: 'Status',
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>()} />,
    maxSize: 140,
  },
  { accessorKey: 'manual', header: 'Manual', maxSize: 60 },
  { accessorKey: 'notes', header: 'Notes', maxSize: 60 },
  { accessorKey: 'notAllowedEffectiveDate', header: 'Not Allowed Effective Date', maxSize: 120 },
  { accessorKey: 'trusts', header: 'Trusts', maxSize: 60 },
  { accessorKey: 'nonProfits', header: 'No Profits', maxSize: 60},
]

interface CoverageGroupOwnershipProps {
  coverageId: string
}

const CoverageGroupOwnership = ({ coverageId }: CoverageGroupOwnershipProps) => {
  const theme = useTheme()
  const { lazyFetch, data = [], isError, isLoading } = useGetCoverageGroupOwnerShip(coverageId)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle={'Coverage Group Ownership'} onExpand={lazyFetch} collapsible isLoading={isLoading}>
      <PhoenixBaseTable
        columns={columns} data={data} isLoading={isLoading} overrides={{
        enableExpanding: true,
        enableExpandAll: true,
        renderDetailPanel: ({ row }) => {
          const { owners } = row.original
          if (owners.length === 0) return <NoResultsFound />
          return owners.map((owner: CoverageGroupOwner, index: number) => (
            <div key={index}>
              <Grid container pt={2} pl={3}>
                <Grid item xs={2}><FieldGroup label={'Owner'} value={handleTitleCase(owner.ownerName)} /></Grid>
                <Grid item xs={2}><FieldGroup label={'Shares Owned'} value={owner.sharesOwned.toFixed(6)} /></Grid>
                <Grid item xs={2}><FieldGroup
                  label={'% Owned'}
                  value={formatPercent(owner.percentageOwned.toFixed(6))}
                /></Grid>
              </Grid>
              <Divider />
            </div>
          ))
        },
        muiDetailPanelProps: () =>
          ({
            sx: {
              backgroundColor: theme.palette.grey[100],
              padding: 0,
            },
          }),
      }}
      />
    </PhoenixBaseCard>
  )
}

export default CoverageGroupOwnership
