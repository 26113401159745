import { MRT_ColumnDef } from 'material-react-table'
import PolicyDetailsCancellationsReintstatementsModel from '../../../models/PolicyDetails/PolicyDetailsCancellationsReintstatementsModel'

/* eslint react/prop-types: 0 */
export const PolicyDetailsCancellationsColumns: MRT_ColumnDef<PolicyDetailsCancellationsReintstatementsModel>[] = [
  {
    accessorKey: 'type',
    header: 'TYPE',
  },
  {
    accessorKey: 'effectiveDate',
    header: 'EFFECTIVE DATE',
  },
  {
    accessorKey: 'receivedDate',
    header: 'RECEIVED DATE',
  },
  {
    accessorKey: 'mailedDate',
    header: 'CANCELLATION MAILED DATE',
    size: 200,
  },
  {
    accessorKey: 'enteredDate',
    header: 'ENTERED DATE',
  },
  {
    accessorKey: 'reason',
    header: 'REASON CODE- DESCRIPTION',
    size: 200,
  },
  {
    accessorKey: 'issuedDate',
    header: 'ISSUED DATE',
  },
]
export default PolicyDetailsCancellationsColumns
